import styled from "styled-components";

export const SigmaMessageContainer = styled.div`

`

export const MessageContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
`