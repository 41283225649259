import Home from "../../pages/home/home.component";

const HomeRoute = () => {
  return (
    <div className="home-route-container">
      <Home/>
    </div>
  )
}

export default HomeRoute